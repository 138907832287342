// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-2023-doty-shortlist-js": () => import("./../../../src/pages/2023-doty-shortlist.js" /* webpackChunkName: "component---src-pages-2023-doty-shortlist-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-dogs-js": () => import("./../../../src/pages/all-dogs.js" /* webpackChunkName: "component---src-pages-all-dogs-js" */),
  "component---src-pages-dog-of-the-year-voting-2023-js": () => import("./../../../src/pages/dog-of-the-year-voting-2023.js" /* webpackChunkName: "component---src-pages-dog-of-the-year-voting-2023-js" */),
  "component---src-pages-doty-voting-2024-form-js": () => import("./../../../src/pages/doty-voting-2024-form.js" /* webpackChunkName: "component---src-pages-doty-voting-2024-form-js" */),
  "component---src-pages-doty-voting-2024-js": () => import("./../../../src/pages/doty-voting-2024.js" /* webpackChunkName: "component---src-pages-doty-voting-2024-js" */),
  "component---src-pages-event-data-js": () => import("./../../../src/pages/event-data.js" /* webpackChunkName: "component---src-pages-event-data-js" */),
  "component---src-pages-index-game-test-custom-js": () => import("./../../../src/pages/index-game-test-custom.js" /* webpackChunkName: "component---src-pages-index-game-test-custom-js" */),
  "component---src-pages-index-game-test-custom-v-3-js": () => import("./../../../src/pages/index-game-test-custom-v3.js" /* webpackChunkName: "component---src-pages-index-game-test-custom-v-3-js" */),
  "component---src-pages-index-game-test-js": () => import("./../../../src/pages/index-game-test.js" /* webpackChunkName: "component---src-pages-index-game-test-js" */),
  "component---src-pages-index-game-test-v-2-js": () => import("./../../../src/pages/index-game-test-v2.js" /* webpackChunkName: "component---src-pages-index-game-test-v-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-introducing-our-heroes-for-2023-js": () => import("./../../../src/pages/introducing-our-heroes-for-2023.js" /* webpackChunkName: "component---src-pages-introducing-our-heroes-for-2023-js" */),
  "component---src-pages-newest-dogs-js": () => import("./../../../src/pages/newest-dogs.js" /* webpackChunkName: "component---src-pages-newest-dogs-js" */),
  "component---src-pages-play-winalot-catcher-js": () => import("./../../../src/pages/play-winalot-catcher.js" /* webpackChunkName: "component---src-pages-play-winalot-catcher-js" */),
  "component---src-pages-share-your-story-js": () => import("./../../../src/pages/share-your-story.js" /* webpackChunkName: "component---src-pages-share-your-story-js" */),
  "component---src-pages-share-your-story-org-js": () => import("./../../../src/pages/share-your-story-org.js" /* webpackChunkName: "component---src-pages-share-your-story-org-js" */),
  "component---src-pages-submit-story-2024-form-js": () => import("./../../../src/pages/submit-story-2024-form.js" /* webpackChunkName: "component---src-pages-submit-story-2024-form-js" */),
  "component---src-pages-submit-story-2024-js": () => import("./../../../src/pages/submit-story-2024.js" /* webpackChunkName: "component---src-pages-submit-story-2024-js" */),
  "component---src-pages-submit-story-2024-org-js": () => import("./../../../src/pages/submit-story-2024-org.js" /* webpackChunkName: "component---src-pages-submit-story-2024-org-js" */),
  "component---src-pages-submit-story-2024-pp-form-js": () => import("./../../../src/pages/submit-story-2024-pp-form.js" /* webpackChunkName: "component---src-pages-submit-story-2024-pp-form-js" */),
  "component---src-pages-submit-story-2024-pp-js": () => import("./../../../src/pages/submit-story-2024-pp.js" /* webpackChunkName: "component---src-pages-submit-story-2024-pp-js" */),
  "component---src-pages-support-the-rspca-js": () => import("./../../../src/pages/support-the-rspca.js" /* webpackChunkName: "component---src-pages-support-the-rspca-js" */),
  "component---src-pages-terms-and-conditions-doty-2024-js": () => import("./../../../src/pages/terms-and-conditions-doty-2024.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-doty-2024-js" */),
  "component---src-pages-terms-and-conditions-doty-js": () => import("./../../../src/pages/terms-and-conditions-doty.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-doty-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-terms-and-conditions-org-js": () => import("./../../../src/pages/terms-and-conditions-org.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-org-js" */),
  "component---src-pages-thanks-data-js": () => import("./../../../src/pages/thanks-data.js" /* webpackChunkName: "component---src-pages-thanks-data-js" */),
  "component---src-pages-thanks-doty-js": () => import("./../../../src/pages/thanks-doty.js" /* webpackChunkName: "component---src-pages-thanks-doty-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-welcome-js": () => import("./../../../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */),
  "component---src-pages-why-choose-winalot-js": () => import("./../../../src/pages/why-choose-winalot.js" /* webpackChunkName: "component---src-pages-why-choose-winalot-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-filter-js": () => import("./../../../src/templates/filter.js" /* webpackChunkName: "component---src-templates-filter-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */)
}

